import React from "react"
import {
  Container,
  List,
  Header,
  Icon,
  Breadcrumb,
  Message,
} from "semantic-ui-react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { formatSpecSection } from "../components/spec_utils"

const SpecPageTemplate = ({ data }) => {
  const { nodes } = data.allMdx
  const node = nodes[0]
  return (
    <Layout>
      <SEO title={`${node.frontmatter.pl} - ${node.parent.name}`} />
      <Container text style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/">
            Home
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section link as={Link} to="/specs">
            Languages
          </Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section>{node.frontmatter.pl}</Breadcrumb.Section>
          <Breadcrumb.Divider icon="right chevron" />
          <Breadcrumb.Section active>{node.parent.name}</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1" dividing>
          <Icon name="code" />
          <Header.Content>
            {node.frontmatter.name}
            <Header.Subheader>
              {node.frontmatter.version}
            </Header.Subheader>
          </Header.Content>
        </Header>

        <MDXProvider components>
          <MDXRenderer>{node.body}</MDXRenderer>
        </MDXProvider>

        <Message icon info>
          <Icon name="certificate" />
          <Message.Content>
            <Message.Header>
              Read the Authoritative Specification
            </Message.Header>
            <p>
              <a href={node.frontmatter.home}>{node.frontmatter.home}</a>
            </p>
          </Message.Content>
        </Message>

        {node.frontmatter.crossReferenced ? 
          <>
            <div style={{ marginTop: "1em" }}>
              <p>
                Our {node.frontmatter.pl} misconceptions relate to the following
                sections of that specification:
              </p>
            </div>
            <List selection verticalAlign="middle">
              {node.frontmatter.sections ? node.frontmatter.sections.map((section, index) => (
                <List.Item
                  as={Link}
                  to={`/specs/${node.parent.name}/${section.section}`}
                  key={index}
                >
                  <List.Icon name="code" verticalAlign="middle" />
                  <List.Content>
                    {formatSpecSection(section.section)}
                    <List.Header>
                      {/* color this like a link, without producing a nested a element */}
                      <span style={{ color: "#4183c4" }}>{section.title}</span>
                    </List.Header>
                  </List.Content>
                </List.Item>
              )) : null}
            </List>
          </>
        : 
          <div style={{marginTop: "1em"}}>
            <p>
              We have not (yet) cross-referenced 
              this language specification with our misconceptions.
              To find misconception by language specification 
              section, <Link to="/specs">pick one of the cross-referenced specifications</Link>.
            </p>
          </div>
      }

      </Container>
    </Layout>
  )
}

export default SpecPageTemplate

export const query = graphql`
  query($glob: String!) {
    allMdx(filter: { fileAbsolutePath: { glob: $glob } }) {
      nodes {
        body
        frontmatter {
          name
          pl
          version
          crossReferenced
          home
          baseUrl
          sections {
            section
            title
            url
          }
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`
